import React from 'react';
import { Button, Form } from "react-bootstrap";
import logo from './Peloton_Logo_White.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const axios = require('axios').default;

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
          <SecretForm />
      </header>
    </div>
  );
}

class SecretForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      secretName: '',
      subSecretName: '',
      appName: 'api-core',
      prodSecret: '',
      stageSecret: '',
      testSecret: '',
      devSecret: ''
    };

  }

  validateForm() {
    return this.state.secretName.length > 0 && this.state.subSecretName.length > 0 && this.state.prodSecret.length > 0;
  }

  handleSubmit = event => {
    console.log(this.state.secretName)
    axios.post('https://kvf9znhul2.execute-api.us-east-1.amazonaws.com/dev',
        {
          secretName: this.state.secretName,
          subSecretName: this.state.subSecretName,
          appName: this.state.appName,
          prodSecret: this.state.prodSecret,
          stageSecret: this.state.stageSecret,
          testSecret: this.state.testSecret,
          devSecret: this.state.devSecret
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
    });
    event.preventDefault();
  }

handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
}

  render() {
    return (
        <div>
            <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="secretName">
                  <Form.Label>Secret Name</Form.Label>
                  <Form.Control
                    autoFocus
                    type="string"
                    value={this.state.secretName}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              <Form.Group controlId="subSecretName">
                  <Form.Label>Sub Secret Name</Form.Label>
                <Form.Control
                  value={this.state.subSecretName}
                  onChange={this.handleChange}
                  type="string"
                  />
              </Form.Group>
              <Form.Group controlId="appName">
                <Form.Label>App Name</Form.Label>
                <Form.Control
                    value={this.state.appName}
                    onChange={this.handleChange}
                    type="string"
                />
              </Form.Group>
              <Form.Group controlId="prodSecret">
                <Form.Label>Prod Value</Form.Label>
                <Form.Control
                    value={this.state.prodSecret}
                    onChange={this.handleChange}
                    type="password"
                />
              </Form.Group>
              <Form.Group controlId="stageSecret">
                <Form.Label>Stage/QA1 Value</Form.Label>
                <Form.Control
                    value={this.state.stageSecret}
                    onChange={this.handleChange}
                    type="password"
                />
              </Form.Group>
              <Form.Group controlId="testSecret">
                <Form.Label>Test/QA2 Value</Form.Label>
                <Form.Control
                    value={this.state.testSecret}
                    onChange={this.handleChange}
                    type="password"
                />
              </Form.Group>
              <Form.Group controlId="devSecret">
                <Form.Label>Dev Value</Form.Label>
                <Form.Control
                    value={this.state.devSecret}
                    onChange={this.handleChange}
                    type="password"
                />
              </Form.Group>
              <Button
                block
                disabled={!this.validateForm()}
                type="submit"
                >
                  Create
              </Button>
            </Form>
        </div>
    );
  }
}

export default App;
